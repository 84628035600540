import axios from 'axios'
import React, { useState } from 'react'
import { ProductCard } from './ProductCard'

const ProductSelection = ({
  userId,
  token,
  url,
  purchasedPhysical,
  purchasedWorkshop,
  physical,
  eha,
  pharmacy,
  international,
  sst,
}) => {
  const [selected, setSelected] = useState(null)
  const [selectedEha, setSelectedEha] = useState(null) // [null, product_id]
  const [selectedPharmacy, setSelectedPharmacy] = useState(null) // [null, product_id]

  return (
    <div id="productSelection">
      <form action={url} method="POST">
        <input type="hidden" name="authenticity_token" value={token} />
        <input
          type="hidden"
          name="product_ids"
          value={[selected, selectedEha, selectedPharmacy]}
        />

        <ProductCard
          cardTitle={'21st MSH ASM 2024 Conference Ticket'}
          products={physical}
          selectedState={selected}
          setSelectedState={setSelected}
          activated={!purchasedPhysical}
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelected(null)
              setSelectedEha(null)
              setSelectedPharmacy(null)
            }
          }}
          handleRadio={() => {
            setSelectedEha(null)
            setSelectedPharmacy(null)
          }}
        />

        {/* if not international, can buy workshop ala-carte */}
        {/* only can buy one workshop */}
        <h6 className="text-center text-navy mt-4">Select Pre-Congress Workshops</h6>
        {/* ------------ EHA CARD ------------ */}
        <ProductCard
          cardTitle={'Pre-Congress EHA Tutorial'}
          products={eha}
          selectedState={selectedEha}
          setSelectedState={setSelectedEha}
          activated={!purchasedWorkshop}
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelectedEha(null)
              setSelectedPharmacy(null)
            }
          }}
          handleRadio={() => {
            setSelectedEha(null)
            setSelectedPharmacy(null)
          }}
        />
        <h6 className="text-center text-muted mt-4">OR</h6>
        {/* ------------ Pharmacy CARD ------------ */}
         <ProductCard
          cardTitle={'Pre-Congress Pharmacy Programme'}
          products={pharmacy}
          selectedState={selectedPharmacy}
          setSelectedState={setSelectedPharmacy}
          activated={!purchasedWorkshop}
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelectedEha(null)
              setSelectedPharmacy(null)
            }
          }}
          handleRadio={() => {
            setSelectedEha(null)
            setSelectedPharmacy(null)
          }}
        />

        {sst && (
          <strong className="d-block mx-auto">
            All purchases will incur 8% SST
          </strong>
        )}
        {/* for international delegate is compulsory to select one of the precongress */}
        <button
          type="submit"
          className="btn text-white btn-primary d-block mx-auto mt-4"
          disabled={international ? (selected === null || (selectedEha === null && selectedPharmacy === null)) : (selected === null && selectedEha === null && selectedPharmacy === null)}
          // disabled={selected === null && selectedEha === null && selectedPharmacy === null}
        >
          Purchase Pass
        </button>
      </form>
    </div>
  )
}

export default ProductSelection
